import { commonStyles } from '../../styles/baseStyles';

const styles = props => {
  const { blue, grey2 } = props.palette.colors;
  const { md, lg, xl } = props.breakpoints.values;

  return {
    ...commonStyles,
    boxHeading: {
      color: blue,
      paddingBottom: '5px'
    },
    subHeroBoxFlexParent: {
      maxWidth: '678px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap'
    },
    subHeroBoxFlexParentInner: {
      paddingTop: '40px',
      maxWidth: '300px',
      margin: '0 auto'
    },
    iconWrapper: {
      paddingBottom: '10px'
    },
    bodySectionHeading: {
      paddingBottom: '40px',
      maxWidth: '490px'
    },
    contentDescriptionOneFlexParent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row wrap',
      paddingBottom: '40px'
    },
    contentDescriptionOneTextContainer: {
      maxWidth: '610px'
    },
    contentBoxesFlexParent: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center'
    },
    contentSectionOne: {
      borderBottom: `1px solid ${grey2}`,
      paddingBottom: '30px'
    },
    contentSectionTwo: {
      paddingTop: '60px'
    },
    contentImageContainer: {
      paddingBottom: '15px'
    },
    contentBoxFlexParentInner: {
      marginBottom: '30px',
      maxWidth: '360px'
    },
    [`@media(min-width: ${md}px) `]: {
      subHeroBoxFlexParentInner: {
        margin: 0
      },
      contentBoxFlexParentInner: {
        maxWidth: '360px',
        marginRight: '20px',
        width: 'calc(50% - 20px)',
        '& :nth-of-type(2n)': {
          marginRight: 0
        }
      }
    },
    [`@media(min-width: ${lg}px) `]: {
      contentBoxesFlexParent: {
        justifyContent: 'space-between'
      },
      contentBoxFlexParentInner: {
        marginRight: 0,
        width: '32%'
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      contentSectionOne: {
        paddingBottom: '60px'
      },
      contentSectionTwo: {
        paddingTop: '90px'
      }
    }
  };
};

export default styles;
