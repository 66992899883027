import React from 'react';

import { Typography, Box } from '@material-ui/core';

const TextSection = ({ classes, subtitle, subtitleClass, title, text, button }) => {
  return (
    <Box style={{ margin: '0 auto', maxWidth: '678px' }}>
      {subtitle && <Typography variant='h5'>{subtitle}</Typography>}
      <Typography style={{ paddingBottom: '20px' }} variant='h2'>
        {title}
      </Typography>
      <Typography variant='body1'>{text}</Typography>
      {button}
    </Box>
  );
};

export default TextSection;
