import React from 'react';

const Arrow = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26.068'
      height='16.995'
      viewBox='0 0 26.068 16.995'
    >
      <g id='Group_619' data-name='Group 619' transform='translate(-11.5 1.004)'>
        <line
          id='Line_1'
          data-name='Line 1'
          x2='23.652'
          transform='translate(11.5 7.854)'
          fill='none'
          stroke={color}
          strokeWidth='3'
        />
        <path
          id='Path_1'
          data-name='Path 1'
          d='M281.543,759.449l7.081,7.854-7.081,7.075'
          transform='translate(-253.124 -759.449)'
          fill='none'
          stroke={color}
          strokeWidth='3'
        />
      </g>
    </svg>
  );
};

export default Arrow;
