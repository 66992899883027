import React, { useState, useEffect } from 'react';

import Mobile from './Mobile';
import DeskTop from './DeskTop';

import theme from '../../styles/theme';

import { useSlugs, useSlugTexts } from '../../hooks';

import Prismic from 'prismic-javascript';
import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Nav = ({ width }) => {
  const { lg } = theme.breakpoints.values;
  const [doc, setDocData] = useState(null);
  const slugs = useSlugs();
  const slugTexts = useSlugTexts();

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'metadata')
      );

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  if (!doc || !slugs || !slugTexts) return <React.Fragment />;

  const links = [
    { value: slugTexts['Services'], to: slugs['Services'] },
    { value: slugTexts['Innovation'], to: slugs['Innovation'] },
    { value: slugTexts['About'], to: slugs['About'] },
    { value: slugTexts['Contact'], to: slugs['Contact'] }
  ];

  const NavComponent = () => {
    if (width < lg) {
      return <Mobile linkArr={links} />;
    }

    return <DeskTop linkArr={links} />;
  };

  return (
    <React.Fragment>
      <NavComponent />
    </React.Fragment>
  );
};

export default Nav;
