import React, { useState } from 'react';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Link as BrowserLink, useLocation } from 'react-router-dom';

import { Typography, Box, List, ListItem } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import styles from './DeskTopStyle';
import theme from '../../styles/theme';

import logo from '../../images/IDAX.png';
import blueLogo from '../../images/IDAX_blue.png';

const DeskTop = ({ classes, linkArr }) => {
  const [showNavBar, setShowNavBar] = useState(true);
  const [scrollpos, setScrollpos] = useState(0);
  const [linkHovered, setLinkHovered] = useState('');
  const scrollBool = scrollpos > -60;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > 0 || prevPos.y > 0 ? true : currPos.y > prevPos.y;
      if (isShow !== showNavBar) setShowNavBar(isShow);
      setScrollpos(currPos.y);
    },
    [showNavBar],
    false,
    false,
    300
  );

  const location = useLocation();

  const { blue, white, orange } = theme.palette.colors;

  const navStyle = {
    top: showNavBar ? 0 : '-100px',
    transition: `top 200ms ${showNavBar ? 'ease-in' : 'ease-out'}`
  };
  const blueLogoStyle = { opacity: scrollBool ? 0 : 1 };
  const logoStyle = { opacity: scrollBool ? 1 : 0 };
  const logoMenuWrapStyle = scrollBool
    ? {
        background: 'transparent'
      }
    : {
        background: white,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
      };

  const handleMouseEnter = e => {
    const { id } = e.currentTarget;
    setLinkHovered(id);
  };

  const handleMouseLeave = e => {
    setLinkHovered('');
  };

  const handleClick = e => {
    setLinkHovered('');
  };

  const links = linkArr.map((obj, i) => {
    const hoveredStyle = {
      color: orange,
      borderBottom: '2px solid transparent'
    };
    const notHoveredStyle = {
      color: scrollBool ? white : blue,
      borderBottom: obj.to === location.pathname ? `2px solid ${orange}` : '2px solid transparent'
    };
    return (
      <ListItem
        id={obj.value}
        onMouseEnter={e => handleMouseEnter(e)}
        onMouseLeave={e => handleMouseLeave(e)}
        key={i}
        className={classes.navLinks}
      >
        <Typography variant='h4' className={classes.h4}>
          <BrowserLink
            style={linkHovered === obj.value ? hoveredStyle : notHoveredStyle}
            to={obj.to}
            className={classes.link}
            onClick={handleClick}
          >
            {obj.value}
          </BrowserLink>
        </Typography>
      </ListItem>
    );
  });

  return (
    <Box role='navigation' style={navStyle} className={classes.nav} component='nav'>
      <Box className={classes.navWrap}>
        <Box style={logoMenuWrapStyle} className={classes.logoMenuWrap}>
          <BrowserLink to='/' onClick={handleClick}>
            <img style={blueLogoStyle} className={classes.logo} src={blueLogo} alt='idax logo' />
            <img style={logoStyle} className={classes.logo} src={logo} alt='idax logo' />
          </BrowserLink>
          <List component='ul' className={classes.linkWrap}>
            {links}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(withTheme(DeskTop));
