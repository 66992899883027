import React from 'react';

export const dynamicText = arr =>
  arr.map(obj => {
    let text = obj.text.split('');
    obj.spans.forEach(span => {
      text.forEach((char, i) => {
        if (i >= span.start && i < span.end) {
          text[i] = <b key={`${i}${char}`}>{char}</b>;
        }
      });
    });
    return (
      <span key={text}>
        {text} <br /> <br />
      </span>
    );
  });
