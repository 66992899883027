const styles = props => {
  const { black, white } = props.palette.colors;
  const { lightWeight } = props.typography;
  const { md } = props.breakpoints.values;
  return {
    wrapper: {
      backgroundColor: black,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20vh'
    },
    text: {
      color: white,
      fontWeight: lightWeight,
      textTransform: 'none',
      textAlign: 'center',
      textDecoration: 'none'
    },
    footerText: {
      fontSize: '0.55rem'
    },
    logoWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '15px'
    },
    logoContainer: {
      paddingBottom: '10px'
    },
    logo: { height: '25px' },
    nav: { display: 'none' },
    [`@media(min-width: ${md}px) `]: {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      },
      logoWrap: { flexDirection: 'row' },
      logoContainer: {
        height: '30px',
        margin: 'auto 0',
        borderRight: `2px solid ${white}`,
        paddingRight: '10px',
        paddingBottom: 0
      },
      logoText: { paddingLeft: '10px' },
      linkWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '4px'
      },
      nav: { display: 'flex', paddingBottom: '15px', paddingTop: 0 },
      navLinks: {
        paddingRight: '25px',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        width: 'revert',
        position: 'static',

        '&:last-of-type': {
          paddingRight: 0
        }
      },
      linkText: {
        cursor: 'pointer',
        '&:hover': {
          color: 'orange',
          textDecoration: 'none'
        }
      },
      footerText: {
        textAlign: 'right'
      }
    }
  };
};

export default styles;
