import React from 'react';

import Doc from './Doc';

const PdfPage = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Doc />
    </div>
  );
};

export default PdfPage;
