const styles = props => {
  const { blue, white, orange } = props.palette.colors;
  const { lightWeight, fontFamily } = props.typography;
  const { md, xl } = props.breakpoints.values;
  return {
    wrapper: {
      backgroundColor: blue
    },
    container: {
      paddingTop: '80px',
      minHeight: '100vh',
      paddingBottom: '150px',
      backgroundColor: blue
    },
    textWrap: {
      paddingTop: '150px'
    },
    heading: {
      paddingBottom: '40px'
    },
    text: {
      color: white
    },
    subHeading: {
      fontWeight: lightWeight,
      transition: 'opacity 250ms ease-in'
    },
    inputWrap: {
      paddingBottom: '60px'
    },
    textField: {
      width: '100%',
      paddingBottom: '30px',
      '&:last-of-type': {
        paddingBottom: 0
      }
    },
    buttonWrap: {
      paddingTop: '10px',
      paddingBottom: '30px',
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      marginBottom: '20px',
      borderRadius: 0,
      textTransform: 'none',
      padding: '20px 0',
      fontSize: '0.85rem'
    },
    textArea: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '20px',
      backgroundColor: blue,
      border: `1px solid ${white}`,
      padding: '10px',
      color: white,
      fontFamily: fontFamily,
      fontSize: '0.85rem'
    },
    mailtoLink: {
      color: white,
      '&:hover': {
        color: orange
      }
    },
    submitButtonContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    submitButton: {
      marginRight: '20px'
    },
    [`@media(min-width: ${md}px) `]: {
      buttonWrap: {
        paddingTop: '10px',
        paddingBottom: '30px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      },
      selectionButton: {
        width: 'calc(50% - 10px)'
      },
      [`@media(min-width: ${xl}px) `]: {
        button: {
          fontSize: '1rem'
        }
      }
    }
  };
};

export default styles;
