import React, { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { CssBaseline, Box } from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/styles';

import { withWindowSizeListener } from 'react-window-size-listener';

import Overlay from './components/Overlay/index';
import Nav from './components/Nav/index';
import Home from './pages/Home/index';
import Services from './pages/Services/index';
import Contact from './pages/Contact/index';
import About from './pages/About/index';
import Innovation from './pages/Innovation/index';
import PdfPage from './pages/PdfPage/index';
import ScrollIntoView from './components/ScrollIntoView/index';

import { useMetadata } from './hooks';
import { prismicApiEndpoint, prismicAccessToken } from './helpers/constants';

import './styles/App.css';
import styles from './styles/App';
import theme from './styles/theme';
import { CSSTransition } from 'react-transition-group';

import scrollSnapPolyfill from 'css-scroll-snap-polyfill';
import smoothscroll from 'smoothscroll-polyfill';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const CustomPage = ({ match, classes, windowWidth, CustomComponent, heroImgSrc }) => (
  <CSSTransition in={match != null} timeout={300} classNames={classes.page} unmountOnExit>
    <Box className={classes.page}>
      <CustomComponent width={windowWidth} heroImgSrc={heroImgSrc} />
    </Box>
  </CSSTransition>
);

const CustomRoutes = ({ windowWidth, classes, pages }) => {
  const metadata = useMetadata();

  if (!metadata) return <React.Fragment />;

  return (
    <React.Fragment>
      {metadata.slugs.map(({ page, path }, i) => {
        if (!pages[page[0].text]) {
          return null;
        }
        return (
          <Route key={`${path[0].text}${i}`} exact path={path[0].text}>
            {({ match }) => (
              <CustomPage
                match={match}
                classes={classes}
                windowWidth={windowWidth}
                CustomComponent={pages[page[0].text].pageName}
                heroImgSrc={pages[page[0].text].heroImgSrc}
              />
            )}
          </Route>
        );
      })}
    </React.Fragment>
  );
};

const App = ({ windowSize, classes }) => {
  const [loading, setLoading] = useState(true);
  const [doc, setDocData] = useState(null);

  const { windowWidth } = windowSize;

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'hero_images')
      );

      if (response) {
        setDocData(response.results[0]);
        setLoading(false);
      }
    };
    fetchData();
    scrollSnapPolyfill();
    smoothscroll.polyfill();
  }, []);

  if (!doc) return <React.Fragment />;

  const pages = {
    Home: {
      pageName: Home,
      heroImgSrc: 'https://idaxdata.sirv.com/hero-vid-small.gif'
    },
    Services: {
      pageName: Services,
      heroImgSrc: doc.data.pages[0].hero_image.url
    },
    Innovation: {
      pageName: Innovation,
      heroImgSrc: doc.data.pages[1].hero_image.url
    },
    About: {
      pageName: About,
      heroImgSrc: doc.data.pages[2].hero_image.url
    },
    Contact: {
      pageName: Contact,
      heroImgSrc: null
    },
    PdfPage: {
      pageName: PdfPage,
      heroImgSrc: null
    }
  };

  return (
    <div id='#app' className='App'>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Overlay loading={loading} width={windowWidth} />
          <ScrollIntoView>
            <Nav width={windowWidth} />
            <Box className={classes.container}>
              <CustomRoutes pages={pages} classes={classes} windowWidth={windowWidth} />
            </Box>
          </ScrollIntoView>
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default withStyles(styles)(withWindowSizeListener(App));
