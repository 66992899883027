import { commonStyles } from '../../styles/baseStyles';

const styles = props => {
  const { grey2, grey4, white, blue, blue3 } = props.palette.colors;
  const { sm, md, xl } = props.breakpoints.values;
  const { lightWeight } = props.typography;
  return {
    ...commonStyles,
    mainTextSection: {
      borderBottom: `1px solid ${grey2}`
    },
    expansionPanelSection: {
      borderTop: `1px solid ${grey2}`,
      maxWidth: '1000px',
      margin: '0 auto'
    },
    expansionBannerFlexParent: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '40px'
    },
    expansionBannerImgContainer: {
      maxWidth: '250px',
      margin: '0 auto'
    },
    expansionBannerTextContainer: {
      maxWidth: '678px',
      margin: '0 auto',
      textAlign: 'center'
    },
    expansionBannerSubHeading: {
      color: grey2,
      fontStyle: 'italic',
      fontWeight: lightWeight,
      paddingBottom: '10px'
    },
    imgPanelSection1: {
      paddingBottom: '60px'
    },
    imgPanelSection: {
      paddingTop: 0
    },
    offsetImageBackground: {
      backgroundColor: white
    },
    imgRight: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    imgRightBackground: {
      backgroundColor: grey4
    },
    imgLeft: {
      display: 'flex',
      flexDirection: 'column'
    },
    imgLeftBackground: {
      backgroundColor: blue3
    },
    whiteText: {
      color: white
    },
    sectionInner: {
      width: '100%'
    },
    sectionFlexParent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imgPanelContainerInner: {
      width: '90%',
      maxWidth: '560px',
      margin: '0 auto',
      padding: '80px 0'
    },
    imgPanelTextContainer: {
      paddingTop: '30px',
      '& h2': {
        paddingBottom: '5px'
      },
      '& h3': {
        paddingBottom: '20px'
      }
    },
    imgPanelIconFlexParent: {
      marginTop: '20px',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    imgPanelIconFlexParentInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: '10px',
      marginBottom: '20px',
      width: 'calc(50% - 10px)',
      '& nth-of-type(2n)': {
        marginRight: 0
      }
    },
    imgPanelIconTextFlexParent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '10px'
    },
    imgPanelIconContainer: {
      width: '50px',
      height: '50px'
    },
    imgPanelIconCaption: {
      width: '147px',
      textAlign: 'center'
    },
    contactBanner: {
      padding: '60px 0'
    },
    preFooterFlexParent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1000px',
      width: '94%',
      margin: '0 auto'
    },
    preFooterButtonWrap: {
      marginTop: '20px'
    },
    contactHeading: {
      color: blue
    },
    preFooterHeading: {
      color: white
    },
    preFooterSubHeading: {
      color: white
    },
    [`@media(min-width: ${sm}px) `]: {
      imgPanelIconFlexParentInner: {
        width: 'calc(33.33% - 20px)',
        '& nth-of-type(3n)': {
          marginRight: 0
        }
      }
    },
    [`@media(min-width: ${md}px) `]: {
      expansionBannerFlexParent: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      expansionBannerImgContainer: {
        maxWidth: '420px',
        width: '45%',
        margin: '0'
      },
      expansionBannerTextContainer: {
        maxWidth: '678px',
        width: '50%',
        margin: '0',
        textAlign: 'left'
      },
      preFooterFlexParent: {
        flexDirection: 'row'
      },
      preFooterButtonWrap: {
        marginTop: 0
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      subHeaderSection: {
        paddingBottom: '110px'
      },
      imgPanelSection1: {
        paddingBottom: '80px'
      },
      imgRight: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      imgLeft: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      textLeft: {
        paddingLeft: '100px'
      },
      textRight: {
        paddingRight: '100px'
      },
      imgPanelContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto'
      },
      imgPanelContainerTemp: {
        display: 'block',
        minHeight: 'auto',
        width: 'auto'
      },
      imgPanelContainerInner: {
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto',
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        padding: 0
      },
      imgPanelTextContainer: {
        width: '45%',
        paddingTop: '25px'
      },
      imgPanelImgContainerRight: {
        width: '40%',
        display: 'flex',
        justifyContent: 'flex-end'
      },
      imgPanelImgContainerLeft: {
        width: '40%',
        display: 'flex',
        justifyContent: 'flex-start'
      },
      imgRightBackground: {
        // background: `${white}`,
        background: `linear-gradient(90deg, ${white} 10%, ${grey4} 10%, ${grey4} 90%, ${white} 90%)`
      },
      imgLeftBackground: {
        // background: `${white}`,
        background: `linear-gradient(90deg, ${white} 10%, ${blue3} 10%, ${blue3} 90%, ${white} 90%)`
      }
    }
  };
};

export default styles;
