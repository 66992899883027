import React from 'react';

import Lottie from 'react-lottie';

import { withStyles, withTheme } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import logo from '../../images/new/Idax_logo_animation_data.json';

import styles from './style';
import theme from '../../styles/theme';

const Overlay = ({ classes, loading, width }) => {
  const { md } = theme.breakpoints.values;
  const lottieWidth = width < md ? 250 : 500;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <React.Fragment>
      <Box
        className={classes.overlay}
        style={{
          zIndex: loading ? 2100 : -1,
          opacity: loading ? 1 : 0,
          transition: 'opacity, 2s ease-in-out',
          transitionDelay: '1s'
        }}
      >
        <Box className={classes.contentWrap}>
          <Lottie options={defaultOptions} width={lottieWidth} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(Overlay));
