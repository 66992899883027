import React, { useState } from 'react';

import { Link as BrowserLink } from 'react-router-dom';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { withStyles, withTheme } from '@material-ui/core/styles';

import { Box, Typography, IconButton, List, ListItem, Drawer } from '@material-ui/core';

import ArrowIcon from '../ArrowIcon/index';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import styles from './MobileStyle';
import theme from '../../styles/theme';

import logo from '../../images/IDAX.png';

const MobileNav = ({ classes, linkArr }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > 0 || prevPos.y > 0 ? true : currPos.y > prevPos.y;
      if (isShow !== showNavBar) setShowNavBar(isShow);
    },
    [showNavBar],
    false,
    false,
    300
  );

  const { orange } = theme.palette.colors;
  const arrowIconStyle = { color: orange, fontSize: '0.9rem', paddingTop: '5px' };
  const logoMenuOuterStyle = {
    top: showNavBar ? 0 : '-100px',
    transition: `top 150ms ${showNavBar ? 'ease-in' : 'ease-out'}`
  };

  const handleClick = e => {
    const { id } = e.currentTarget;

    if (id === 'logo') {
      setShowMenu(false);
    } else {
      setShowMenu(!showMenu);
    }
  };

  const Links = () =>
    linkArr.map(obj => (
      <ListItem key={obj.to} className={classes.listItem}>
        <Typography variant='h3' className={classes.textWrap}>
          <BrowserLink
            onClick={handleClick}
            to={obj.to}
            name={obj.to}
            className={`${classes.text} ${classes.heading}`}
          >
            {obj.value}
          </BrowserLink>
          <ArrowIcon style={arrowIconStyle} className={classes.arrow} />
        </Typography>
      </ListItem>
    ));

  return (
    <Box role='navigation' component='nav'>
      <Box style={logoMenuOuterStyle} className={classes.logoMenuOuter}>
        <BrowserLink id='logo' onClick={handleClick} to='/'>
          <img className={classes.logo} src={logo} alt='idax logo' />
        </BrowserLink>
        <IconButton
          value='menu'
          onClick={handleClick}
          disableFocusRipple
          disableRipple
          className={classes.menuIcon}
        >
          {!showMenu && <MenuIcon className={classes.openMenu} />}
          {showMenu && <CloseIcon className={classes.closeMenu} />}
        </IconButton>
      </Box>
      <Drawer anchor='right' open={showMenu} onClose={handleClick}>
        <Box component='nav' className={classes.nav}>
          <List component='ul' className={classes.navList}>
            <Links />
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default withStyles(styles)(withTheme(MobileNav));
