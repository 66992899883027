import React, { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';

import ReactFullpage from '@fullpage/react-fullpage';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import Header from '../../components/Header/index';
import TextSection from '../../components/TextSection/';
import LargeButton from '../../components/LargeButton/index';
import Footer from '../../components/Footer/index';

import styles from './style';
import theme from '../../styles/theme';

import { overlay, background } from '../../helpers/backgroundObjs';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

import { dynamicText } from '../../components/DynamicText';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Innovation = ({ classes, width, heroImgSrc }) => {
  const { xl } = theme.breakpoints.values;
  const { white, orange } = theme.palette.colors;
  const outer = theme.spacing(width < xl ? 10 : 20);

  const [doc, setDocData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'innovation')
      );

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  if (!doc) return <React.Fragment />;

  const sectionData = new Array(4).fill().map((obj, i) => {
    let classesObj = {};
    const right = {
      outerBackground: 'whiteBackground',
      imgOrientation: 'imgRight',
      textOrientation: 'textLeft'
    };
    const left = {
      imgOrientation: 'imgLeft',
      textOrientation: 'textRight'
    };
    switch (i) {
      case 0:
        classesObj = {
          ...right,
          innerBackground: 'lightBlueBackgroundInner',
          text: 'greyText'
        };
        break;
      case 1:
        classesObj = {
          ...left,
          outerBackground: 'darkGreyBackground',
          text: 'whiteText'
        };
        break;
      case 2:
        classesObj = {
          ...right,
          innerBackground: 'darkBlueBackground',
          text: 'whiteText'
        };
        break;
      case 3:
        classesObj = {
          ...left,
          outerBackground: 'lightBlueBackground',
          text: 'greyText'
        };
        break;
      default:
        break;
    }

    return {
      img: doc.data.panels[i].image.url,
      subtitle: doc.data.panels[i].subtitle[0].text,
      title: doc.data.panels[i].title[0].text,
      text: dynamicText(doc.data.panels[i].text),
      classes: classesObj
    };
  });

  const imgPanelSections = sectionData.map((obj, i) => (
    <div key={obj.subtitle} className='section'>
      <div className={`${classes.sectionInner} ${classes.sectionFlexParent}`}>
        <div className={`${classes[obj.classes.outerBackground]} ${classes.sectionInner}`}>
          <div className={classes.imgPanelContainer}>
            <div
              className={`${classes[obj.classes.innerBackground]} ${classes.imgPanelContainerTemp}`}
            >
              {obj.classes.imgOrientation === 'imgLeft' ? (
                <div className={classes.imgPanelContainerInner}>
                  <div className={classes[obj.classes.imgOrientation]}>
                    <div className={classes.imgPanelImgContainer}>
                      <img src={obj.img} alt='' />
                    </div>
                    <div
                      className={`${classes.imgPanelTextContainer} ${
                        classes[obj.classes.textOrientation]
                      }`}
                    >
                      <Typography className={classes[obj.classes.text]} variant='h2'>
                        {obj.title}
                      </Typography>
                      <Typography className={classes[obj.classes.text]} variant='h3'>
                        {obj.subtitle}
                      </Typography>
                      <Typography className={classes[obj.classes.text]} variant='body1'>
                        {obj.text}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.imgPanelContainerInner}>
                  <div className={classes[obj.classes.imgOrientation]}>
                    <div
                      className={`${classes.imgPanelTextContainer} ${
                        classes[obj.classes.textOrientation]
                      }`}
                    >
                      <Typography className={classes[obj.classes.text]} variant='h2'>
                        {obj.title}
                      </Typography>
                      <Typography className={classes[obj.classes.text]} variant='h3'>
                        {obj.subtitle}
                      </Typography>
                      <Typography className={classes[obj.classes.text]} variant='body1'>
                        {obj.text}
                      </Typography>
                    </div>
                    <div className={classes.imgPanelImgContainer}>
                      <img src={obj.img} alt='' />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <ReactFullpage
      autoScrolling={width > xl ? true : false}
      licenseKey={'24311C6B-04FD4749-A01E2D68-CC997748'}
      scrollBar={true}
      scrollingSpeed={1000}
      render={({ fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className='section'>
              <Header
                width={width}
                height={'100vh'}
                overlay={overlay('0, 0, 0, 0.53')}
                background={background(heroImgSrc, 'center', 'center')}
                buttonClick={() => fullpageApi.moveSectionDown()}
                headerText={doc.data.header[0].text}
              />
            </div>
            <div className='section'>
              <Box
                bgcolor={white}
                className={`${classes.sectionInner} ${classes.sectionFlexParent}`}
              >
                <div className={classes.uberContainerInner}>
                  <TextSection
                    width={width}
                    paddingTop={outer}
                    paddingBottom={outer}
                    title={doc.data.header_title[0].text}
                    text={dynamicText(doc.data.header_text)}
                  />
                </div>
              </Box>
            </div>
            {imgPanelSections}
            <div className='section'>
              <Box className={`${classes.sectionInner}`}>
                <div style={background(doc.data.prefooter_image.url, 'center', 'center')}>
                  <div className={classes.preFooter} style={overlay('0, 0, 0, 0.53')}>
                    <div className={classes.preFooterFlexParent}>
                      <div>
                        <Typography className={classes.preFooterHeading} variant='h2'>
                          {doc.data.prefooter_heading[0].text}
                        </Typography>
                        <Typography className={classes.preFooterSubHeading} variant='body1'>
                          {doc.data.prefooter_text[0].text}
                        </Typography>
                      </div>
                      <div className={classes.preFooterButtonWrap}>
                        <LargeButton
                          text={doc.data.prefooter_button_text[0].text}
                          backgroundColor={orange}
                          textColor={white}
                          iconColor={white}
                          path={doc.data.prefooter_button_path[0].text}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Footer width={width} />
              </Box>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default withStyles(styles)(withTheme(Innovation));
