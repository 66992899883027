import { commonStyles } from '../../styles/baseStyles';

const styles = props => {
  const { grey2, grey3, blue, blue2, white } = props.palette.colors;
  const { sm, md, lg, xl } = props.breakpoints.values;
  const { midWeight } = props.typography;

  return {
    ...commonStyles,
    img: {
      height: 'auto',
      maxWidth: '100%',
      display: 'block'
    },
    ourValuesSection: {
      maxWidth: '678px',
      margin: '0 auto'
    },
    ourValuesHeading: {
      textTransform: 'uppercase',
      color: blue,
      paddingTop: '20px',
      paddingBottom: '10px'
    },
    valueFlexParent: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px'
    },
    valueNum: {
      border: `2px solid ${blue}`,
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      padding: '15px',
      color: blue,
      fontSize: '0.8rem',
      marginRight: '10px'
    },
    value: {
      color: blue,
      fontWeight: midWeight
    },
    diversitySection: {
      backgroundColor: blue2
    },
    diversitySectionFlexParent: {
      display: 'flex',
      justifyContent: 'center'
    },
    diversityStatementContainer: {
      backgroundColor: white,
      display: 'inline-block',
      padding: '30px 20px'
    },
    cultureTextContainer: {
      paddingTop: '20px',
      maxWidth: '700px'
    },
    diversityStatementDate: {
      textTransform: 'uppercase',
      fontSize: '0.6rem'
    },
    teamContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginTop: '30px'
    },
    teamMemberWrap: {
      width: 'calc(50% - 7.5px)',
      marginLeft: '15px',
      marginBottom: '30px',
      '&:nth-of-type(odd)': { marginLeft: 0 }
    },
    teamImgContainer: {
      border: `1px solid ${grey3}`,
      marginBottom: '5px'
    },
    teamH4: {
      color: blue
    },
    teamH5: {
      color: grey2
    },
    imgPanelSection: {
      paddingBottom: '80px'
    },
    imgPanelFlexParent: {
      maxWidth: '320px',
      margin: '0 auto',
      display: 'grid',
      gridTemplateRows: 'repeat(29, 20px [row-start])',
      gridTemplateColumns: 'repeat(29, 1fr [col-start])'
    },
    panel0: {
      gridColumn: '1 / span 18'
    },
    panel1: {
      gridColumn: '3 / span 25',
      gridRowStart: '12'
    },
    panel2: {
      gridColumn: '14 / span 16',
      gridRowStart: '20'
    },
    [`@media(min-width: ${sm}px) `]: {
      ourValuesFlexParent: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
      },
      valueGroupFlexParent: {
        width: '33%'
      }
    },
    [`@media(min-width: ${md}px) `]: {
      valueGroupFlexParent: {
        width: '31%'
      },
      valueFlexParent: {
        marginBottom: '20px'
      },
      teamContainer: {
        justifyContent: 'center'
      },
      teamMemberWrap: {
        width: 'calc(25% - 15px)',
        marginLeft: 0,
        marginRight: '20px',
        marginBottom: '40px',
        '&:nth-of-type(4n)': { marginRight: 0 }
      },
      imgPanelFlexParent: {
        maxWidth: '768px',
        gridTemplateRows: 'repeat(18, 20px [row-start])',
        gridTemplateColumns: 'repeat(29, 1fr [col-start])'
      },
      panel0: {
        gridColumn: '1 / span 7',
        gridRowStart: '3'
      },
      panel1: {
        gridColumn: '10 / span 11',
        gridRowStart: '9'
      },
      panel2: {
        gridColumn: '23 / span 7',
        gridRowStart: '1'
      }
    },
    [`@media(min-width: ${lg}px) `]: {
      teamContainer: {
        marginTop: '40px'
      },
      imgPanelFlexParent: {
        width: '100%'
      },
      diversityStatementContainer: {
        padding: '40px 30px'
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      subHeaderContainer: {
        paddingBottom: '110px'
      },
      imgPanelFlexParent: {
        width: '100%',
        maxWidth: '1450px',
        gridTemplateRows: 'repeat(36, 20px [row-start])'
      },
      imgPanelContainer: {
        justifySelf: 'start',
        margin: 0
      },
      panel0: {
        gridColumn: '1 / span 7',
        gridRowStart: '6',
        maxWidth: 'none'
      },
      panel1: {
        gridColumn: '10 / span 11',
        gridRowStart: '16',
        maxWidth: 'none'
      },
      panel2: {
        gridColumn: '23 / span 7',
        gridRowStart: '1',
        maxWidth: 'none'
      },
      imgPanelSection: {
        paddingBottom: '160px'
      }
    }
  };
};

export default styles;
