import React, { Component } from 'react';

import { Container, Box, IconButton, Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Fade from 'react-reveal/Fade';

import styles from './style';

export class Header extends Component {
  render() {
    const { classes, headerText, buttonClick, height, overlay, background } = this.props;

    return (
      <Box role='banner' component='header' className={classes.header}>
        <Box style={background}>
          <Box className={classes.wrapper} height={height} style={overlay}>
            <Box className={classes.content} height={height}>
              <Fade>
                <Container>
                  <Box className={classes.textWrap}>
                    <Typography className={classes.text} variant='h1'>
                      {headerText}
                    </Typography>
                    <IconButton
                      aria-label='scroll to next section'
                      className={classes.arrowButton}
                      disableFocusRipple
                      disableRipple
                      onClick={buttonClick}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Box>
                </Container>
              </Fade>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTheme(Header));
