import React, { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import _ from 'lodash';

import * as emailjs from 'emailjs-com';

import { withStyles, withTheme } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Box,
  Container,
  MenuItem,
  Button,
  TextareaAutosize
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import MuiButton from '@material-ui/core/Button';

import ArrowIcon from '../../components/ArrowIcon/index';
import Footer from '../../components/Footer';

import styles from './style';
import theme from '../../styles/theme';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

import { camelCase } from '../../helpers/backgroundObjs';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const locations = [
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'District Of Columbia',
    value: 'DC'
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  }
];

const Contact = ({ classes, width }) => {
  const { blue, orange, white } = theme.palette.colors;
  const { xl } = theme.breakpoints.values;

  const [state, setState] = useState({
    name: '',
    email: '',
    location: '',
    interestedIn: [],
    interestButtonsArr: [],
    message: '',
    hoveredButton: false,
    buttonValue: '',
    messageSubmitted: false
  });
  const [doc, setDocData] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(``);
  const [messageSubmitted, setMessageSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(Prismic.Predicates.at('document.type', 'contact'));

      if (response) {
        setDocData(response.results[0]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (doc) {
      const buttonVals = _.flatMap(doc.data.buttons.map(x => x.value[0].text));

      const interestButtonsArr = buttonVals.map(arrItem => ({
        value: camelCase(arrItem),
        label: arrItem,
        ischecked: 'false'
      }));

      setState(oldState => ({ ...oldState, interestButtonsArr: interestButtonsArr }));
    }
  }, [doc]);

  if (!doc) return <React.Fragment />;

  const {
    name,
    email,
    location,
    interestedIn,
    interestButtonsArr,
    message,
    hoveredButton,
    buttonValue
  } = state;

  const SubmitButton = withStyles({
    root: {
      position: 'relative',
      color: white,
      borderRadius: 0,
      border: `1px solid ${messageSubmitted ? orange : white}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '50px',
      fontSize: '0.65rem',
      '&:hover': {
        color: white,
        boxShadow: 'none',
        '& $endIcon': {
          right: '15px'
        }
      },
      [`@media(min-width: ${xl}px) `]: {
        fontSize: '0.7rem'
      }
    },
    contained: {
      boxShadow: 'none',
      backgroundColor: messageSubmitted ? orange : 'transparent',
      '&:hover': {
        backgroundColor: orange,
        borderColor: orange
      }
    },
    endIcon: {
      position: 'absolute',
      right: '22px',
      top: '50%',
      transform: 'translateY(-50%)',
      margin: 0,
      transition: 'all 250ms ease'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })(MuiButton);

  //* selects/deselects buttons & maintains array of button selections
  const handleClick = e => {
    const buttonChecked = e.currentTarget.value;

    setState(prevState => {
      let { interestButtonsArr, interestedIn } = prevState;

      interestButtonsArr = interestButtonsArr.map(obj =>
        obj.value === buttonChecked
          ? { ...obj, ischecked: obj.ischecked === 'false' ? 'true' : 'false' }
          : obj
      );

      interestedIn = interestButtonsArr
        .map(obj => (obj.ischecked === 'true' ? obj.value : null))
        .filter(arrItem => arrItem !== null);

      return {
        ...prevState,
        interestButtonsArr,
        interestedIn
      };
    });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setState(oldState => ({
      ...oldState,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const emailSubject = interestedIn.join(', ');

    let templateParams = {
      reply_to: email,
      from_name: name,
      to_name: 'smartdata@idaxdata.com',
      location: location,
      subject: emailSubject,
      message_html: message
    };

    emailjs
      .send('default_service', 'template_ve19APU7', templateParams, 'user_UkQYJaXpfna1x1Cx8iNOE')
      .then(
        result => {
          setMessageSuccess(
            result.text === 'OK'
              ? `Message sent - we'll get back to you shortly`
              : 'Message not sent - sorry about that, please try again'
          );

          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );

    setState(oldState => {
      let { interestButtonsArr } = oldState;

      interestButtonsArr = interestButtonsArr.map(obj => {
        obj.ischecked = 'false';
        return obj;
      });
      return {
        name: '',
        email: '',
        location: '',
        interestedIn: [],
        interestButtonsArr,
        message: '',
        hoveredButton: false,
        buttonValue: ''
      };
    });

    setMessageSubmitted(true);
    setTimeout(() => {
      setMessageSubmitted(false);
    }, 5000);
  };

  const buttonEnter = e => {
    const { value } = e.currentTarget;
    setState(oldState => ({
      ...oldState,
      hoveredButton: true,
      buttonValue: value
    }));
  };

  const buttonLeave = () => {
    setState(oldState => ({
      ...oldState,
      hoveredButton: false,
      buttonValue: ''
    }));
  };

  const buttons = interestButtonsArr.map(arrItem => (
    <Button
      key={arrItem.value}
      onMouseEnter={buttonEnter}
      onMouseLeave={buttonLeave}
      onClick={handleClick}
      value={arrItem.value}
      className={`${classes.button} ${classes.selectionButton}`}
      variant='outlined'
      style={{
        backgroundColor: arrItem.ischecked === 'true' ? orange : blue,
        border: `1px solid ${
          arrItem.ischecked === 'true' || (hoveredButton && arrItem.value === buttonValue)
            ? orange
            : white
        }`,
        color:
          hoveredButton && arrItem.value === buttonValue && arrItem.ischecked === 'false'
            ? orange
            : white
      }}
    >
      {arrItem.label}
    </Button>
  ));

  return (
    <React.Fragment>
      <Box role='banner' component='header' bgcolor={blue} className={classes.wrapper}>
        <Container style={{ maxWidth: width < 1920 ? '650px' : '800px' }}>
          <Box className={classes.textWrap}>
            <Typography className={classes.heading} variant='h1'>
              {doc.data.header[0].text}
            </Typography>

            <Typography
              className={classes.text}
              variant='body1'
              dangerouslySetInnerHTML={{
                __html: doc.data.text[0].text
              }}
            ></Typography>
          </Box>
        </Container>
      </Box>
      <Box role='main' component='main' bgcolor={blue}>
        <Container
          className={classes.container}
          style={{ maxWidth: width < 1920 ? '650px' : '800px' }}
        >
          <form name='contact' action='' method='POST' onSubmit={handleSubmit}>
            <Box className={classes.inputWrap}>
              <TextField
                onChange={handleChange}
                type='text'
                className={classes.textField}
                id='name'
                name='name'
                label='Name'
                value={name}
              />
              <TextField
                required
                onChange={handleChange}
                type='email'
                className={classes.textField}
                id='email'
                name='email'
                label='Email'
                value={email}
              />
              <TextField
                onChange={handleChange}
                className={classes.textField}
                select
                id='select-location'
                label='Select Location'
                name='location'
                value={location}
                SelectProps={{
                  IconComponent: ExpandMoreIcon
                }}
              >
                {locations.map(location => (
                  <MenuItem key={location.value} value={location.value}>
                    {location.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Typography className={`${classes.text} ${classes.subHeading}`} variant='h4'>
              I'm interested in...
            </Typography>
            <Box className={classes.buttonWrap}>{buttons}</Box>
            <Typography className={`${classes.text} ${classes.subHeading}`} variant='h4'>
              Message *
            </Typography>
            <TextareaAutosize
              required
              rows={10}
              className={classes.textArea}
              onChange={handleChange}
              id='message'
              label='Message'
              name='message'
              value={message}
            />
            <Box className={classes.submitButtonContainer}>
              <SubmitButton
                className={classes.submitButton}
                type='submit'
                variant='contained'
                endIcon={
                  messageSubmitted ? (
                    <CheckIcon />
                  ) : (
                    <ArrowIcon style={{ color: white, fontSize: '1.1rem', paddingTop: '5px' }} />
                  )
                }
              >
                Submit
              </SubmitButton>
              <Typography
                style={{ opacity: messageSubmitted ? 1 : 0 }}
                className={`${classes.text} ${classes.subHeading}`}
                variant='h4'
              >
                {messageSuccess}
              </Typography>
            </Box>
          </form>
        </Container>
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(Contact));
