import React, { useState, useEffect } from 'react';

import { Link as BrowserLink } from 'react-router-dom';

import { Container, Typography, Box, List, ListItem } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import styles from './style';

import logo from '../../images/IDAX.png';

import { useSlugs, useSlugTexts } from '../../hooks';

import Prismic from 'prismic-javascript';
import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Footer = ({ classes }) => {
  const [doc, setDocData] = useState(null);
  const slugs = useSlugs();
  const slugTexts = useSlugTexts();

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'metadata')
      );

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  if (!doc || !slugs || !slugTexts) return <React.Fragment />;

  const linkArr = [
    { value: slugTexts['Services'], to: slugs['Services'] },
    { value: slugTexts['Innovation'], to: slugs['Innovation'] },
    { value: slugTexts['About'], to: slugs['About'] },
    { value: slugTexts['Contact'], to: slugs['Contact'] }
  ];

  const links = linkArr.map((obj, i) => {
    return (
      <ListItem key={i} className={classes.navLinks}>
        <Typography variant='body1'>
          <BrowserLink to={obj.to} className={`${classes.text} ${classes.linkText}`}>
            {obj.value}
          </BrowserLink>
        </Typography>
      </ListItem>
    );
  });

  return (
    <Box role='contentinfo' component='footer' className={classes.wrapper}>
      <Container className={classes.container} maxWidth='xl'>
        <Box className={classes.logoWrap}>
          <Box className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt='Idax logo' />
          </Box>
          <Typography className={`${classes.logoText} ${classes.text}`} variant='body1'>
            {doc.data['slogan'][0].text}
          </Typography>
        </Box>
        <Box className={classes.linkWrapper}>
          <List component='ul' className={classes.nav}>
            {links}
          </List>
          <Typography className={`${classes.footerText} ${classes.text}`} variant='h3'>
            <span style={{ fontSize: '0.7rem' }}>©</span> {doc.data['copyright'][0].text}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default withStyles(styles)(withTheme(Footer));
