const styles = props => {
  return {
    container: {
      position: 'relative'
    },

    page: {
      position: 'absolute',
      width: '100%'
    },

    pageEnter: {
      opacity: 0
    },

    pageEnterActive: {
      opacity: 1,
      transition: 'opacity 500ms ease-in-out'
    },

    pageExit: {
      opacity: 1
    },

    pageExitActive: {
      opacity: 0,
      transition: 'opacity 500ms ease-in-out'
    }
  };
};

export default styles;
