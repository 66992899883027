import React, { useState, useEffect, useRef } from 'react';
import Prismic from 'prismic-javascript';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Header from '../../components/Header/index';
import TextSection from '../../components/TextSection/index';
import Footer from '../../components/Footer';
import ExpandingPanels from '../../components/ExpandingPanels';
import LargeButton from '../../components/LargeButton/index';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';
import { overlay, background } from '../../helpers/backgroundObjs';

import styles from './style';
import theme from '../../styles/theme';

import { useSlugs } from '../../hooks';

import { scrollToRef } from '../../components/ScrollToRef';
import { dynamicText } from '../../components/DynamicText';

import freightAndLoad from '../../images/Services/icons2/Freight-and-Load Zones.png';
import micromobility from '../../images/Services/icons2/Micromobility.png';
import originDestination from '../../images/Services/icons2/Origin-Destination.png';
import parking from '../../images/Services/icons2/Parking.png';
import pedestrian from '../../images/Services/icons2/Pedestrian.png';
import bicycles from '../../images/Services/icons2/Bicycles.png';
import probeData from '../../images/Services/icons2/Probe-Data.png';
import rideSharing from '../../images/Services/icons2/Ride-sharing.png';
import roadVolumes from '../../images/Services/icons2/Road-Volumes.png';
import travelTime from '../../images/Services/icons2/Travel-time.png';
import turningMovements from '../../images/Services/icons2/Turning-Movements.png';
import videoStudies from '../../images/Services/icons2/Video-Studies.png';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Services = ({ classes, width, heroImgSrc }) => {
  const [doc, setDocData] = useState(null);
  const slugs = useSlugs();
  const mainRef = useRef(null);

  const { white, blue, orange } = theme.palette.colors;

  useEffect(() => {
    const fetchData = async () => {
      if (!doc) {
        const response = await PrismicClient.query(
          Prismic.Predicates.at('document.type', 'data_acquisition')
        );

        if (response) {
          setDocData(response.results[0]);
        }
      }
    };
    fetchData();
  }, [doc]);

  if (!doc || !slugs) return <React.Fragment />;

  const [imgRight, imgLeft] = doc.data.bottom_content_section;
  const imgPanelSection1Icons = {
    turningMovements: {
      img: turningMovements,
      caption: imgRight.bottom_content_icon_labels[0].text
    },
    roadVolumes: {
      img: roadVolumes,
      caption: imgRight.bottom_content_icon_labels[1].text
    },
    videoStudies: {
      img: videoStudies,
      caption: imgRight.bottom_content_icon_labels[2].text
    },
    probeData: {
      img: probeData,
      caption: imgRight.bottom_content_icon_labels[3].text
    },
    travelTime: {
      img: travelTime,
      caption: imgRight.bottom_content_icon_labels[4].text
    },
    originDestination: {
      img: originDestination,
      caption: imgRight.bottom_content_icon_labels[5].text
    }
  };

  const imgPanelSection2Icons = {
    parking: {
      img: parking,
      caption: imgLeft.bottom_content_icon_labels[0].text
    },
    micromobility: {
      img: micromobility,
      caption: imgLeft.bottom_content_icon_labels[1].text
    },
    pedestrian: {
      img: pedestrian,
      caption: imgLeft.bottom_content_icon_labels[2].text
    },
    bicycles: {
      img: bicycles,
      caption: imgLeft.bottom_content_icon_labels[3].text
    },
    rideSharing: {
      img: rideSharing,
      caption: imgLeft.bottom_content_icon_labels[4].text
    },
    freightAndLoad: {
      img: freightAndLoad,
      caption: imgLeft.bottom_content_icon_labels[5].text
    }
  };

  const imgPanelIcon = obj =>
    Object.keys(obj).map(icon => (
      <div key={icon} className={classes.imgPanelIconFlexParentInner}>
        <div className={classes.imgPanelIconTextFlexParent}>
          <div className={classes.imgPanelIconContainer}>
            <img src={obj[icon].img} alt='' />
          </div>
          <Typography className={classes.imgPanelIconCaption} variant='h4'>
            {obj[icon].caption}
          </Typography>
        </div>
      </div>
    ));

  const ImgPanelSection1 = () => (
    <div className={`${classes.sectionInner} ${classes.sectionFlexParent}`}>
      <div className={`${classes.offsetImageBackground} ${classes.sectionInner}`}>
        <div className={classes.imgPanelContainer}>
          <div className={classes.imgRightBackground}>
            <div className={classes.imgPanelContainerInner}>
              <div className={classes.imgRight}>
                <div className={`${classes.imgPanelTextContainer} ${classes.textLeft}`}>
                  <Typography className={classes.whiteText} variant='h2'>
                    {imgRight.bottom_content_header[0].text}
                  </Typography>
                  <Typography className={classes.whiteText} variant='body1'>
                    {dynamicText(imgRight.bottom_content_body)}
                  </Typography>
                  <div className={classes.imgPanelIconFlexParent}>
                    {imgPanelIcon(imgPanelSection1Icons)}
                  </div>
                </div>
                <div className={classes.imgPanelImgContainerRight}>
                  <img src={imgRight.bottom_content_image.url} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const ImgPanelSection2 = () => (
    <div className={`${classes.sectionInner} ${classes.sectionFlexParent}`}>
      <div className={`${classes.offsetImageBackground} ${classes.sectionInner}`}>
        <div className={classes.imgPanelContainer}>
          <div className={classes.imgLeftBackground}>
            <div className={classes.imgPanelContainerInner}>
              <div className={classes.imgLeft}>
                <div className={classes.imgPanelImgContainerLeft}>
                  <img src={imgLeft.bottom_content_image.url} alt='' />
                </div>
                <div className={`${classes.imgPanelTextContainer} ${classes.textRight}`}>
                  <Typography className={classes.whiteText} variant='h2'>
                    {imgLeft.bottom_content_header[0].text}
                  </Typography>
                  <Typography className={classes.whiteText} variant='body1'>
                    {dynamicText(imgLeft.bottom_content_body)}
                  </Typography>
                  <div className={classes.imgPanelIconFlexParent}>
                    {imgPanelIcon(imgPanelSection2Icons)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Header
        width={width}
        height={'100vh'}
        overlay={overlay('0, 0, 0, 0.3')}
        background={background(heroImgSrc, 'center', 'center')}
        buttonClick={() => scrollToRef(mainRef)}
        headerText={doc.data.hero_title[0].text}
      />
      <div role='main'>
        <div
          ref={mainRef}
          className={`${classes.whiteSection} ${classes.sectionPadding} ${classes.subHeaderSection}`}
        >
          <div className={classes.uberContainerInner}>
            <TextSection
              width={width}
              classes={classes}
              title={doc.data.content_section[0].section_header[0].text}
              text={dynamicText(doc.data.content_section[0].section_body_text)}
            />
          </div>
        </div>
        <div className={classes.whiteSection}>
          <div className={classes.uberContainerInner}>
            <div className={`${classes.expansionPanelSection} ${classes.sectionPadding}`}>
              <div className={classes.expansionBannerFlexParent}>
                <div className={classes.expansionBannerImgContainer}>
                  <img
                    className={classes.img}
                    src={doc.data.content_section[1].content_image.url}
                    alt=''
                  />
                </div>
                <div className={classes.expansionBannerTextContainer}>
                  <Typography variant='h2'>
                    {doc.data.content_section[1].section_header[0].text}
                  </Typography>
                  <Typography className={classes.expansionBannerSubHeading} variant='h3'>
                    {doc.data.content_section[1].section_sub_header[0].text}
                  </Typography>
                  <Typography variant='body1'>
                    {dynamicText(doc.data.content_section[1].section_body_text)}
                  </Typography>
                </div>
              </div>
              <ExpandingPanels content={doc.data.accordion_panels} />
            </div>
          </div>
        </div>
        <div
          className={`${classes.whiteSection} ${classes.sectionPadding} ${classes.imgPanelSection}`}
        >
          <div className={classes.imgPanelSection1}>
            <ImgPanelSection1 />
          </div>

          <ImgPanelSection2 />
        </div>
        <div className={classes.greySection}>
          <div className={`${classes.greySection} ${classes.contactBanner}`}>
            <div className={classes.preFooterFlexParent}>
              <Typography className={classes.contactHeading} variant='h3'>
                {doc.data.contact_banner[0].heading[0].text}
              </Typography>
              <div className={classes.preFooterButtonWrap}>
                <LargeButton
                  text={doc.data.contact_banner[0].button_text[0].text}
                  backgroundColor={blue}
                  textColor={blue}
                  iconColor={orange}
                  path={slugs['Contact']}
                />
              </div>
            </div>
          </div>
          <div
            style={background(
              doc.data.prefooter_banner[0].background_image.url,
              'center',
              'center'
            )}
          >
            <div className={classes.sectionPadding}>
              <div className={classes.preFooterFlexParent}>
                <div>
                  <Typography className={classes.preFooterHeading} variant='h2'>
                    {doc.data.prefooter_banner[0].heading[0].text}
                  </Typography>
                  <Typography className={classes.preFooterSubHeading} variant='body1'>
                    {doc.data.prefooter_banner[0].sub_heading[0].text}
                  </Typography>
                </div>
                <div className={classes.preFooterButtonWrap}>
                  <LargeButton
                    text={doc.data.prefooter_banner[0].button_text[0].text}
                    backgroundColor={orange}
                    textColor={white}
                    iconColor={white}
                    path={slugs['Innovation']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer width={width} />
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(Services));
