import { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import _ from 'lodash';

import { prismicApiEndpoint, prismicAccessToken } from '../helpers/constants';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

export const useMetadata = () => {
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (!metadata) {
        const response = await PrismicClient.query(
          Prismic.Predicates.at('document.type', 'metadata')
        );

        if (response) {
          setMetadata(response.results[0].data);
        }
      }
    };
    fetchMetadata();
  }, [metadata]);

  return metadata;
};

export const useSlugs = () => {
  const metadata = useMetadata();
  const [slugs, setSlugs] = useState(null);

  useEffect(() => {
    if (metadata) {
      setSlugs(
        _.reduce(
          metadata.slugs.map(slug => ({
            [slug.page[0].text]: slug.path[0].text
          })),
          (prev, curr) => ({ ...prev, ...curr })
        )
      );
    }
  }, [metadata]);

  return slugs;
};

export const useSlugTexts = () => {
  const metadata = useMetadata();
  const [slugTexts, setSlugTexts] = useState(null);

  useEffect(() => {
    if (metadata) {
      setSlugTexts(
        _.reduce(
          metadata.slugs.map(slug => ({
            [slug.page[0].text]: slug.text[0].text
          })),
          (prev, curr) => ({ ...prev, ...curr })
        )
      );
    }
  }, [metadata]);

  return slugTexts;
};
