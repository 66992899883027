import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography, Box, Container } from '@material-ui/core';

import styles from './style';
import theme from '../../styles/theme';

const PreFooter = ({
  classes,
  width,
  background,
  overlay,
  header,
  subtitle,
  button,
  textShadow,
  maxWidth
}) => {
  const applyTextShadow = textShadow ? { textShadow: ' rgba(0, 0, 0, 0.675) 0px 3px 6px' } : {};

  const { xl } = theme.breakpoints.values;

  const containerWidth = width < 1920 ? maxWidth : `${xl}px`;

  return (
    <React.Fragment>
      <Box style={background} id='preFooter'>
        <Box style={overlay}>
          <Container
            style={{
              maxWidth: containerWidth
            }}
            className={classes.container}
          >
            <Box>
              <Typography style={applyTextShadow} className={classes.header} variant='h3'>
                {header}
              </Typography>
              <Typography style={applyTextShadow} className={classes.subtitle} variant='body1'>
                {subtitle}
              </Typography>
              {button}
            </Box>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(PreFooter));

PreFooter.propTypes = {
  maxWidth: PropTypes.string
};

PreFooter.defaultProps = {
  maxWidth: '992px'
};
