import { commonStyles } from '../../styles/baseStyles';

const styles = props => {
  const { white, blue2, grey4, blue3 } = props.palette.colors;
  const { xl, md } = props.breakpoints.values;
  return {
    ...commonStyles,
    sectionInner: {
      minHeight: '100vh',
      width: '100%'
    },
    sectionFlexParent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imgPanelContainerTemp: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      width: '100%'
    },
    imgPanelContainerInner: {
      width: '90%',
      maxWidth: '560px',
      margin: '0 auto',
      padding: '80px 0'
    },
    imgRight: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    imgLeft: {
      display: 'flex',
      flexDirection: 'column'
    },
    greyText: {
      color: grey4
    },
    whiteText: {
      color: white
    },
    whiteBackground: {
      background: white
    },
    lightBlueBackground: {
      background: blue2
    },
    lightBlueBackgroundInner: {
      background: blue2
    },
    darkGreyBackground: {
      background: grey4
    },
    darkBlueBackground: {
      background: blue3
    },
    imgPanelTextContainer: {
      paddingTop: '30px',
      '& h2': {
        paddingBottom: '5px'
      },
      '& h3': {
        paddingBottom: '20px'
      }
    },
    preFooter: {
      minHeight: '80vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    preFooterFlexParent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1000px',
      width: '94%',
      margin: '0 auto'
    },
    preFooterHeading: {
      color: white,
      textAlign: 'center'
    },
    preFooterSubHeading: {
      color: white
    },
    preFooterButtonWrap: {
      marginTop: '20px'
    },
    [`@media(min-width: ${md}px) `]: {
      preFooterFlexParent: {
        flexDirection: 'row'
      },
      preFooterHeading: {
        textAlign: 'left'
      },
      preFooterButtonWrap: {
        marginTop: 0
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      imgPanelContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto'
      },
      imgPanelContainerTemp: {
        display: 'block',
        minHeight: 'auto',
        width: 'auto'
      },
      imgPanelContainerInner: {
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto',
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        padding: 0
      },
      imgRight: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      imgLeft: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      textLeft: {
        paddingRight: '100px'
      },
      textRight: {
        paddingLeft: '100px'
      },
      imgPanelTextContainer: {
        width: '45%',
        paddingTop: 0
      },
      imgPanelImgContainer: {
        width: '40%'
      },
      lightBlueBackgroundInner: {
        // background: `${white}`,
        background: `linear-gradient(90deg, ${white} 10%, ${blue2} 10%, ${blue2} 90%, ${white} 90%)`
      },
      darkBlueBackground: {
        // background: `${white}`,
        background: `linear-gradient(90deg, ${white} 10%, ${blue3} 10%, ${blue3} 90%, ${white} 90%)`
      }
    }
  };
};

export default styles;
