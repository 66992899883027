const styles = props => {
  const { blue } = props.palette.colors;
  return {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      width: '100%',
      height: '100vh',
      left: 0,
      top: 0,
      backgroundColor: blue
    },
    contentWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;
