import { createMuiTheme } from '@material-ui/core/styles';
import * as baseStyles from './baseStyles';

const theme = createMuiTheme({
  palette: {
    colors: {
      white: baseStyles.colours.white,
      blue: baseStyles.colours.blue,
      blue2: baseStyles.colours.blue2,
      blue3: baseStyles.colours.blue3,
      orange: baseStyles.colours.orange,
      black: baseStyles.colours.black,
      grey1: baseStyles.colours.grey1,
      grey2: baseStyles.colours.grey2,
      grey3: baseStyles.colours.grey3,
      grey4: baseStyles.colours.grey4
    }
  },
  typography: {
    fontFamily: baseStyles.fonts.roboto.join(', '),
    htmlFontSize: 20,
    letterSpacing: 'normal',
    lightWeight: 400,
    midWeight: 500,
    heavyWeight: 700
  },
  breakpoints: {
    values: {
      xs: baseStyles.breakPoints.extraSmall,
      sm: baseStyles.breakPoints.small,
      md: baseStyles.breakPoints.medium,
      lg: baseStyles.breakPoints.large,
      xl: baseStyles.breakPoints.extraLarge
    }
  },
  spacing: 10,
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: baseStyles.colours.orange
        }
      }
    },
    MuiInputBase: {
      root: {
        color: baseStyles.colours.white
      }
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderColor: baseStyles.colours.white
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderColor: baseStyles.colours.white
        },
        '&::after': {
          borderColor: baseStyles.colours.orange
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: baseStyles.colours.white,
        fontSize: '0.85rem',
        [`@media(min-width: ${baseStyles.breakPoints.large}px) `]: {
          fontSize: '0.9rem'
        }
      },
      formControl: {
        top: '-3px'
      }
    },
    MuiSelect: {
      icon: {
        color: baseStyles.colours.white
      }
    },
    MuiDrawer: {
      paperAnchorRight: {
        backgroundColor: baseStyles.colours.blue,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
      }
    }
  }
});

theme.typography.h1 = {
  fontSize: '1.85rem',
  lineHeight: 1.25,
  fontWeight: theme.typography.midWeight,
  color: baseStyles.colours.white,
  [`@media(min-width: ${theme.breakpoints.values.md}px) `]: {
    fontSize: '2.5rem'
  },
  [`@media(min-width: ${theme.breakpoints.values.xl}px) `]: {
    fontSize: '2.75rem'
  }
};

theme.typography.h2 = {
  fontSize: '1.75rem',
  lineHeight: 1.25,
  fontWeight: theme.typography.heavyWeight,
  color: baseStyles.colours.blue,
  [`@media(min-width: ${theme.breakpoints.values.xl}px) `]: {
    fontSize: '1.9rem'
  }
};

theme.typography.h3 = {
  fontSize: '1rem',
  lineHeight: 1.25,
  fontWeight: theme.typography.heavyWeight,
  color: baseStyles.colours.white,
  [`@media(min-width: ${theme.breakpoints.values.xl}px) `]: {
    fontSize: '1.2rem'
  }
};

theme.typography.h4 = {
  fontSize: '0.8rem',
  lineHeight: 1.25,
  fontWeight: theme.typography.heavyWeight,
  color: baseStyles.colours.white,
  [`@media(min-width: ${theme.breakpoints.values.xl}px) `]: {
    fontSize: '0.9rem'
  }
};

theme.typography.h5 = {
  fontSize: '0.7rem',
  lineHeight: 1.25,
  textTransform: 'uppercase',
  fontWeight: theme.typography.midWeight,
  color: baseStyles.colours.blue
};

theme.typography.body1 = {
  fontSize: '0.7rem',
  lineHeight: 1.5,
  fontWeight: theme.typography.lightWeight,
  color: baseStyles.colours.black,
  [`@media(min-width: ${theme.breakpoints.values.xl}px) `]: {
    fontSize: '0.8rem'
  }
};

export default theme;
