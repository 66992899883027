const styles = props => {
  const { blue, orange } = props.palette.colors;
  const { xl } = props.breakpoints.values;

  return {
    header: {
      backgroundColor: blue
    },
    wrapper: {
      width: '100%'
    },
    content: {
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    text: {
      paddingLeft: '15px',
      paddingBottom: '20px',
      textShadow: ' rgba(0, 0, 0, 0.675) 0px 3px 6px'
    },
    arrowButton: {
      color: orange,
      border: `2px solid ${orange}`,
      padding: '5px',
      marginLeft: '15px'
    },
    [`@media(min-width: ${xl}px) `]: {
      textWrap: {
        position: 'relative'
      },
      arrowButton: {
        position: 'absolute',
        top: '15px',
        left: '-80px'
      }
    }
  };
};

export default styles;
