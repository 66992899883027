import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';

import theme from '../../styles/theme';
import styles from './style';

import { dynamicText } from '../../components/DynamicText';

import paidTransactions from '../../images/Services/icons1/ Paid-Transactions.png';
import tubeAndRadar from '../../images/Services/icons1/ Tube-&-Radar.png';
import probeData from '../../images/Services/icons1/Large-Scale-probe-data.png';
import licensePlateCollection from '../../images/Services/icons1/License-Plate-Collection.png';
import manualAndVideo from '../../images/Services/icons1/Manual-and-Video Acquisition.png';
import wifiBluetooth from '../../images/Services/icons1/Wifi-Bluetooth.png';

const { blue, grey2 } = theme.palette.colors;

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: `1px solid ${grey2}`,
    borderLeft: 0,
    borderRight: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid transparent',
    marginBottom: -1,
    padding: '0 24px 0 0',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    margin: '20px 0',
    '&$expanded': {
      margin: '20px 0'
    }
  },
  expanded: {},
  expandIcon: {
    color: blue,
    border: `2px solid ${blue}`,
    padding: '1px'
  }
})(MuiAccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0 10% 0 0',
    flexDirection: 'column'
  }
}))(MuiAccordionDetails);

const ExpandingPanels = ({ classes, content }) => {
  const [expanded, setExpanded] = React.useState();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [panel1, panel2, panel3, panel4, panel5] = content;
  const panelIcons = {
    manualAndVideo: {
      caption: 'Manual & Video Acquisition',
      img: manualAndVideo
    },
    licensePlateCollection: {
      caption: 'License Plate Collection',
      img: licensePlateCollection
    },
    wifiBluetooth: {
      caption: 'Wifi / Bluetooth',
      img: wifiBluetooth
    },
    probeData: {
      caption: 'Large-Scale probe data',
      img: probeData
    },
    tubeAndRadar: {
      caption: 'Tube & Radar',
      img: tubeAndRadar
    },
    paidTransactions: {
      caption: 'Paid Transactions',
      img: paidTransactions
    }
  };

  const iconImg = Object.keys(panelIcons).map(icon => (
    <div key={icon} className={classes.expansionPanelIconFlexParentInner}>
      <div className={classes.expansionPanelIconContainer}>
        <img src={panelIcons[icon].img} alt='' />
      </div>
      <Typography className={classes.expansionPanelIconCaption} variant='h4'>
        {panelIcons[icon].caption}
      </Typography>
    </div>
  ));

  return (
    <React.Fragment>
      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title} variant='h3'>
            {panel1.accordion_header[0].text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.textContainer}>
            <Typography className={classes.text} variant='body1'>
              {dynamicText(panel1.accordion_body)}
            </Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title} variant='h3'>
            {panel2.accordion_header[0].text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.textImgFlexParent}>
            <div className={classes.textContainerImg}>
              <Typography className={classes.text} variant='body1'>
                {dynamicText(panel2.accordion_body)}
              </Typography>
            </div>
            <div className={classes.expansionPanelImgContainer}>
              <img src={panel2.accordion_image.url} alt='' />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title} variant='h3'>
            {panel3.accordion_header[0].text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.textContainer}>
            <Typography className={classes.text} variant='body1'>
              {dynamicText(panel3.accordion_body)}
            </Typography>
          </div>
          <div className={classes.expansionPanelIconFlexParent}>{iconImg}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title} variant='h3'>
            {panel4.accordion_header[0].text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.textContainer}>
            <Typography className={classes.text} variant='body1'>
              {dynamicText(panel4.accordion_body)}
            </Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title} variant='h3'>
            {panel5.accordion_header[0].text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.textImgFlexParent}>
            <div className={classes.textContainerImg}>
              <Typography className={classes.text} variant='body1'>
                {dynamicText(panel5.accordion_body)}
              </Typography>
            </div>
            <div className={classes.expansionPanelImgContainer2}>
              <img src={panel5.accordion_image.url} alt='' />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
};

export default withStyles(styles)(ExpandingPanels);
