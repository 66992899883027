export const fonts = {
  roboto: ['Roboto', 'sans-serif']
};

export const colours = {
  white: '#ffffff',
  blue: '#192857',
  blue2: '#D2DFED',
  blue3: '#3A4669',
  orange: '#F79B27',
  black: '#292828',
  grey1: '#F4F4F4',
  grey2: '#707070',
  grey3: '#D4DCE4',
  grey4: '#3A434D'
};

export const breakPoints = {
  extraSmall: 320,
  small: 480,
  medium: 768,
  large: 992,
  extraLarge: 1400
};

export const commonStyles = {
  whiteSection: {
    backgroundColor: colours.white
  },
  greySection: {
    backgroundColor: colours.grey1
  },
  sectionPadding: {
    padding: '80px 0',
    [`@media(min-width: ${breakPoints.extraLarge}px) `]: {
      padding: '160px 0'
    }
  },
  uberContainerOuter: {
    maxWidth: '1600px',
    width: '94%',
    margin: '0 auto',
    [`@media(min-width: ${breakPoints.extraLarge}px) `]: {
      width: '100%'
    }
  },
  uberContainerInner: {
    maxWidth: '1100px',
    width: '94%',
    margin: '0 auto',
    [`@media(min-width: ${breakPoints.extraLarge}px) `]: {
      width: '100%'
    }
  }
};
