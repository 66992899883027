import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import Arrow from '../../icons/Arrow';

const ArrowIcon = props => {
  return (
    <SvgIcon {...props}>
      <Arrow color={props.style.color} />
    </SvgIcon>
  );
};

export default ArrowIcon;
