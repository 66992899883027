import React from 'react';
import PropTypes from 'prop-types';

import { Link as BrowserLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import ArrowIcon from '../ArrowIcon/index';

import theme from '../../styles/theme';

const Link = React.forwardRef((props, ref) => <BrowserLink innerRef={ref} {...props} />);

const LargeButton = ({ text, textColor, backgroundColor, iconColor, path, width, onClick }) => {
  const { xl } = theme.breakpoints.values;
  const { white } = theme.palette.colors;

  const Button = withStyles({
    root: {
      position: 'relative',
      color: textColor,
      borderRadius: 0,
      border: `2px solid ${backgroundColor}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '50px',
      fontSize: '0.65rem',
      minWidth: '153px',
      '&:hover': {
        color: white,
        boxShadow: 'none',
        '& $endIcon': {
          right: '15px'
        }
      },
      [`@media(min-width: ${xl}px) `]: {
        fontSize: '0.7rem'
      }
    },
    contained: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: backgroundColor
      }
    },
    endIcon: {
      position: 'absolute',
      right: '22px',
      top: '50%',
      transform: 'translateY(-50%)',
      margin: 0,
      transition: 'all 250ms ease'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })(MuiButton);

  return (
    <Button
      name={path}
      component={Link}
      to={path}
      onClick={onClick}
      variant='contained'
      endIcon={<ArrowIcon style={{ color: iconColor, fontSize: '1.1rem', paddingTop: '5px' }} />}
    >
      {text}
    </Button>
  );
};

LargeButton.propTypes = {
  path: PropTypes.string
};

LargeButton.defaultProps = {
  path: '/'
};

export default LargeButton;
