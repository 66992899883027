import React, { useState, useEffect, useRef } from 'react';
import Prismic from 'prismic-javascript';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

import Header from '../../components/Header/index';
import TextSection from '../../components/TextSection/';
import PreFooter from '../../components/PreFooter/index';
import LargeButton from '../../components/LargeButton/index';
import Footer from '../../components/Footer/index';

import styles from './style';
import theme from '../../styles/theme';

import { overlay, background } from '../../helpers/backgroundObjs';

import { useSlugs } from '../../hooks';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

import { scrollToRef } from '../../components/ScrollToRef';
import { dynamicText } from '../../components/DynamicText';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const About = ({ classes, width, heroImgSrc }) => {
  const [doc, setDocData] = useState(null);
  const slugs = useSlugs();
  const mainRef = useRef(null);

  const { white, orange, blue } = theme.palette.colors;

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(Prismic.Predicates.at('document.type', 'company'));

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  if (!doc || !slugs) return <React.Fragment />;

  const teamArr = doc.data.team_members.map(obj => ({
    name: obj.name[0].text,
    position: obj.job_title[0].text,
    imgSrc: obj.image_url.url
  }));

  const theTeam = teamArr.map(obj => {
    return (
      <div key={obj.name} className={classes.teamMemberWrap}>
        <div className={classes.teamImgContainer}>
          <img className={classes.img} src={obj.imgSrc} alt={obj.name} />
        </div>
        <Typography className={classes.teamH4} variant='h4'>
          {obj.name}
        </Typography>
        <Typography className={classes.teamH5} variant='h5'>
          {obj.position}
        </Typography>
      </div>
    );
  });

  const imgArr = doc.data.about_image_panels.map(obj => ({
    imgScr: obj.image.url,
    altText: obj.alt_text[0].text
  }));

  const imgPanels = imgArr.map((obj, i) => {
    let panelClass = `panel${i}`;
    return (
      <div key={obj.altText} className={`${classes.imgPanelContainer} ${classes[panelClass]}`}>
        <img className={classes.img} src={obj.imgScr} alt={obj.altText} />
      </div>
    );
  });

  const splitValues = () => {
    const tempArr = doc.data.values.map((obj, i) => {
      obj.position = i + 1;
      return obj;
    });
    const valuesArr = [];
    while (tempArr.length) {
      valuesArr.push(tempArr.splice(0, 3));
    }
    return valuesArr;
  };

  const valueGroups = splitValues().map((arr, i) => (
    <ul key={`${arr[0].text}${i}`} className={classes.valueGroupFlexParent}>
      {arr.map((obj, i) => (
        <li key={obj.text} className={classes.valueFlexParent}>
          <span className={classes.valueNum}>{obj.position}</span>
          <Typography className={classes.value} variant='body1'>
            {obj.text}
          </Typography>
        </li>
      ))}
    </ul>
  ));

  return (
    <React.Fragment>
      <Header
        width={width}
        height={'100vh'}
        overlay={overlay('0, 0, 0, 0.53')}
        background={background(heroImgSrc, 'center', 'center')}
        buttonClick={() => scrollToRef(mainRef)}
        headerText={doc.data.header[0].text}
      />
      <div role='main'>
        <div
          ref={mainRef}
          className={`${classes.whiteSection} ${classes.sectionPadding} ${classes.subHeaderContainer}`}
        >
          <div className={classes.uberContainerInner}>
            <TextSection
              classes={classes}
              width={width}
              subtitle={doc.data.sub_hero_sub_heading[0].text}
              title={doc.data.sub_hero_heading[0].text}
              text={dynamicText(doc.data.sub_hero_text)}
            />
            <div className={classes.ourValuesSection}>
              <Typography className={classes.ourValuesHeading} variant='h3'>
                {doc.data.values_heading[0].text}
              </Typography>
              <div className={classes.ourValuesFlexParent}>{valueGroups}</div>
            </div>
          </div>
        </div>
        <div className={`${classes.whiteSection} ${classes.imgPanelSection}`}>
          <div className={classes.imgPanelFlexParent}>{imgPanels}</div>
        </div>
        <div className={`${classes.diversitySection} ${classes.sectionPadding}`}>
          <div className={`${classes.diversitySectionFlexParent} ${classes.uberContainerInner}`}>
            <div className={classes.diversityStatementContainer}>
              <Typography variant='h2'>{doc.data.culture_header[0].text}</Typography>
              <Typography className={classes.diversityStatementDate} variant='body1'>
                {doc.data.date[0].text}
              </Typography>
              <div className={classes.cultureTextContainer}>
                {dynamicText(doc.data.culture_text)}
              </div>
              <div className={classes.buttonWrap}>
                <LargeButton
                  text={doc.data.culture_button_text[0].text}
                  backgroundColor={blue}
                  textColor={blue}
                  iconColor={orange}
                  path={slugs[doc.data.footer_cta_link[0].text]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.whiteSection} ${classes.sectionPadding} ${classes.teamSection}`}>
          <Container>
            {<Typography variant='h2'>{doc.data.team_header[0].text}</Typography>}
            <div className={classes.teamContainer}>{theTeam}</div>
          </Container>
        </div>
        <PreFooter
          width={width}
          background={background(doc.data.footer_image.url, 'center', 'bottom')}
          overlay={overlay('0, 0, 0, 0.53')}
          textShadow
          header={doc.data.footer_header[0].text}
          subtitle={doc.data.footer_subtitle[0].text}
          button={
            <LargeButton
              path={slugs[doc.data.footer_cta_link[0].text]}
              text={doc.data.footer_cta_text[0].text}
              backgroundColor={orange}
              textColor={white}
              iconColor={white}
            />
          }
        />
      </div>
      <Footer width={width} />
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(About));
