const styles = props => {
  const { white, orange, blue } = props.palette.colors;
  const { md } = props.breakpoints.values;
  const { heavyWeight } = props.typography;
  return {
    logoMenuOuter: {
      position: 'fixed',
      right: 0,
      left: 0,
      width: '100%',
      zIndex: 2000,
      padding: '20px 20px 20px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundColor: blue,
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
    },
    logo: {
      width: '55px'
    },
    menuIcon: { padding: 0, borderRadius: 'revert' },
    openMenu: { color: white, fontSize: '1.5rem' },
    closeMenu: { color: orange, fontSize: '1.5rem' },
    nav: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: '30%'
    },
    navList: {
      paddingBottom: '15px'
    },
    textWrap: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        '& $text': {
          color: orange
        },
        '& $arrow': {
          opacity: 1
        }
      }
    },
    text: {
      color: white,
      textTransform: 'none',
      textDecoration: 'none',
      textAlign: 'left'
    },
    heading: {
      fontSize: '1rem',
      fontWeight: heavyWeight
    },
    link: {
      fontSize: '0.8rem'
    },
    arrow: {
      marginLeft: '5px',
      opacity: 0,
      transition: 'opacity 500ms ease-in-out'
    },
    [`@media(min-width: ${md}px) `]: {
      logo: {
        width: props.spacing(7)
      },
      menuIcon: {
        fontSize: '1.5rem'
      },
      heading: {
        fontSize: '1.2rem',
        fontWeight: heavyWeight
      },
      link: {
        fontSize: '0.9rem'
      }
    }
  };
};

export default styles;
