const overlay = rgba => {
  return {
    backgroundImage: `linear-gradient(rgba(${rgba}), rgba(${rgba}))`
  };
};

const background = (img, position1, position2) => {
  return {
    backgroundImage: `url("${img}")`,
    backgroundSize: 'cover',
    backgroundPosition: `${position1} ${position2}`
  };
};

const camelCase = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export { overlay, background, camelCase };
