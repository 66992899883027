const styles = props => {
  const { white, blue } = props.palette.colors;
  const { midWeight } = props.typography;
  return {
    nav: {
      width: '100%',
      position: 'fixed',
      zIndex: 2000
    },
    navWrap: {
      position: 'relative'
    },
    logoMenuWrap: {
      position: 'absolute',
      width: '100%',
      padding: '30px 30px 30px 30px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 2000,
      transition: 'all 200ms ease-in-out'
    },
    logo: {
      width: '70px',
      position: 'absolute',
      top: '30px',
      left: '30px',
      transition: 'opacity 200ms ease-in-out'
    },
    linkWrap: {
      display: 'flex',
      padding: 0
    },
    navLinks: {
      paddingRight: '25px',
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      width: 'revert',
      position: 'static',
      '&:last-of-type': {
        paddingRight: 0
      }
    },
    h2: {
      fontSize: ' 1.7rem'
    },
    h3: { color: blue, fontSize: ' 0.75rem', fontWeight: midWeight },
    h4: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: ' 0.8rem',
      fontWeight: midWeight
    },
    body1: {
      fontSize: ' 0.7rem'
    },
    link: {
      textTransform: 'uppercase',
      textDecoration: 'none',
      paddingBottom: '5px',
      transition: 'all 200ms ease-in-out'
    },
    linkIcon: {
      transition: 'color 200ms ease-in-out'
    },
    dropDown: {
      position: 'absolute',
      width: '100%',
      backgroundColor: white,
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      zIndex: 1900,
      transition: 'all 500ms ease-in-out'
    },
    dropDownContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '80px',
      paddingRight: '20px',
      paddingLeft: '20px',
      paddingBottom: '40px'
    },
    dropDownTitleWrap: {
      flexBasis: '20%'
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexBasis: '70%',
      paddingTop: '8px'
    },
    buttonSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingRight: '30px',
      '&:last-of-type': {
        paddingRight: 0
      }
    },
    button: {
      paddingTop: '10px',
      display: 'flex'
    }
  };
};

export default styles;
