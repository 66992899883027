const styles = props => {
  const { white } = props.palette.colors;
  const { lg, xl } = props.breakpoints.values;
  const { lightWeight } = props.typography;
  return {
    container: {
      paddingTop: '100px',
      paddingBottom: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '80vh'
    },
    header: {
      color: white,
      textTransform: 'none',
      fontSize: '1.2rem',
      paddingBottom: '10px'
    },
    subtitle: {
      color: white,
      fontWeight: lightWeight,
      fontSize: '0.8rem',
      paddingBottom: '30px'
    },
    [`@media(min-width: ${lg}px) `]: {
      header: {
        fontSize: '1.5rem'
      },
      subtitle: {
        fontSize: '1.22rem'
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      container: {
        paddingTop: '200px',
        paddingBottom: '200px'
      }
    }
  };
};

export default styles;
