const styles = props => {
  const { blue } = props.palette.colors;
  const { sm, md, xl } = props.breakpoints.values;

  return {
    title: {
      color: blue
    },
    text: {
      paddingBottom: '20px'
    },
    textImgFlexParent: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    textContainerImg: { paddingTop: '20px' },
    expansionPanelImgContainer: {
      maxWidth: '320px',
      margin: '0 auto'
    },
    expansionPanelImgContainer2: {
      maxWidth: '360px',
      margin: '0 auto'
    },
    expansionPanelIconFlexParent: {
      display: 'flex',
      flexFlow: 'row wrap'
    },
    expansionPanelIconFlexParentInner: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
      textAlign: 'center'
    },
    expansionPanelIconContainer: {
      maxWidth: '80px'
    },
    expansionPanelIconCaption: {
      color: blue,
      maxWidth: '115px',
      paddingTop: '10px'
    },
    [`@media(min-width: ${sm}px) `]: {
      expansionPanelIconFlexParentInner: {
        width: 'calc(50% - 20px)',
        marginRight: '20px',
        '&:nth-of-type(2n)': {
          marginRight: 0
        }
      }
    },
    [`@media(min-width: ${md}px) `]: {
      textContainer: {
        maxWidth: '80%'
      },
      expansionPanelIconFlexParent: {
        maxWidth: '80%'
      },
      expansionPanelIconFlexParentInner: {
        width: 'calc(33.33% - 40px)',
        marginRight: '20px',
        '&:nth-of-type(2n)': {
          marginRight: '20px'
        },
        '&:nth-of-type(3n)': {
          marginRight: 0
        }
      },
      expansionPanelIconContainer: {
        maxWidth: '90px'
      },
      textImgFlexParent: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: '15px'
      },
      textContainerImg: {
        paddingTop: 0,
        width: '50%',
        marginRight: '30px'
      },
      textContainerImg2: {
        maxWidth: '450px'
      },
      expansionPanelImgContainer: {
        maxWidth: '400px',
        margin: 0
      },
      expansionPanelImgContainer2: {
        maxWidth: '450px',
        margin: 0
      }
    },
    [`@media(min-width: ${xl}px) `]: {
      expansionPanelIconCaption: {
        maxWidth: '130px'
      }
    }
  };
};

export default styles;
