import React, { useState, useEffect, useRef } from 'react';
import Prismic from 'prismic-javascript';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Header from '../../components/Header/index';
import TextSection from '../../components/TextSection/index';
import LargeButton from '../../components/LargeButton/index';

import PreFooter from '../../components/PreFooter/index';
import Footer from '../../components/Footer/index';

import { useSlugs } from '../../hooks';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';
import { overlay, background } from '../../helpers/backgroundObjs';

import styles from './style';
import theme from '../../styles/theme';

import { scrollToRef } from '../../components/ScrollToRef';
import { dynamicText } from '../../components/DynamicText';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Home = ({ classes, width, heroImgSrc }) => {
  const [doc, setDocData] = useState(null);
  const slugs = useSlugs();
  const mainRef = useRef(null);

  const { white, blue, orange } = theme.palette.colors;

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'home_page')
      );

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  if (!doc || !slugs) return <React.Fragment />;

  const subHeroBoxes = doc.data.sub_hero_text_box.map((obj) => (
    <div key={obj.heading[0].text} className={classes.subHeroBoxFlexParentInner}>
      <div className={classes.iconWrapper}>
        <img src={obj.icon.url} alt='' />
      </div>
      <Typography className={classes.boxHeading} variant='h4'>
        {obj.heading[0].text}
      </Typography>
      <Typography className={classes.boxText} variant='body1'>
        {dynamicText(obj.text)}
      </Typography>
    </div>
  ));

  const ContentBoxes = ({ arr }) =>
    arr.map((obj) => (
      <div key={obj.heading[0].text} className={classes.contentBoxFlexParentInner}>
        <div className={classes.contentImageContainer}>
          <img src={obj.image.url} alt='' />
        </div>
        <Typography className={classes.boxHeading} variant='h4'>
          {obj.heading[0].text}
        </Typography>
        <Typography className={classes.boxText} variant='body1'>
          {dynamicText(obj.text)}
        </Typography>
      </div>
    ));

  return (
    <React.Fragment>
      <Header
        width={width}
        height={'100vh'}
        overlay={overlay('25, 41, 87, 0.53')}
        background={background(heroImgSrc, 'center', 'center')}
        headerText={doc.data.hero_heading[0].text}
        buttonClick={() => scrollToRef(mainRef)}
        doc={doc}
      />
      <div role='main'>
        <div ref={mainRef} className={`${classes.whiteSection} ${classes.sectionPadding}`}>
          <div className={classes.uberContainerInner}>
            <TextSection
              title={doc.data.sub_hero_heading[0].text}
              text={dynamicText(doc.data.sub_hero_text)}
            />
            <div className={classes.subHeroBoxFlexParent}>{subHeroBoxes}</div>
          </div>
        </div>
        <div className={`${classes.greySection} ${classes.sectionPadding}`}>
          <div className={classes.uberContainerInner}>
            {/* <div className={classes.bodySectionHeading}>
            <Typography variant='h5'>{doc.data.body_content_subheading[0].text}</Typography>
            <Typography variant='h2'>{doc.data.body_content_main_heading[0].text}</Typography>
          </div> */}
            <div className={classes.contentDescriptionOneFlexParent}>
              <div className={classes.contentDescriptionOneTextContainer}>
                <Typography className={classes.boxHeading} variant='h3'>
                  {doc.data.body_content_section_1_heading[0].text}
                </Typography>
                <Typography variant='body1'>
                  {dynamicText(doc.data.body_content_section_1_text)}
                </Typography>
              </div>
              <div>
                <LargeButton
                  text={doc.data.body_content_section_1_button_text[0].text}
                  backgroundColor={blue}
                  textColor={blue}
                  iconColor={orange}
                  path={slugs['Innovation']}
                />
              </div>
            </div>
            <div className={`${classes.contentBoxesFlexParent} ${classes.contentSectionOne}`}>
              <ContentBoxes arr={doc.data.body_content_section_1_box} />
            </div>
            <div className={`${classes.contentBoxesFlexParent} ${classes.contentSectionTwo}`}>
              <div
                className={`${classes.contentDescriptionTwo} ${classes.contentBoxFlexParentInner}`}
              >
                <div className={classes.contentDescriptionTwoTextContainer}>
                  <Typography className={classes.boxHeading} variant='h3'>
                    {doc.data.body_content_section_2_heading[0].text}
                  </Typography>
                  <Typography variant='body1'>
                    {dynamicText(doc.data.body_content_section_2_text)}
                  </Typography>
                </div>
                <div>
                  <LargeButton
                    text={doc.data.body_content_section_2_button_text[0].text}
                    backgroundColor={blue}
                    textColor={blue}
                    iconColor={orange}
                    path={slugs['Services']}
                  />
                </div>
              </div>
              <ContentBoxes arr={doc.data.body_content_section_2_box} />
            </div>
          </div>
        </div>
        <PreFooter
          width={width}
          background={background(
            doc.data.prefooter_section_background_image.url,
            'center',
            'bottom'
          )}
          overlay={overlay('0, 0, 0, 0.53')}
          textShadow
          header={doc.data.prefooter_section_heading[0].text}
          subtitle={doc.data.prefooter_section_text[0].text}
          button={
            <LargeButton
              text={doc.data.perfooter_section_button_text[0].text}
              backgroundColor={orange}
              textColor={white}
              iconColor={white}
              path={slugs['Contact']}
            />
          }
        />
      </div>
      <Footer width={width} />
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(Home));
