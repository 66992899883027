import React, { useState, useEffect } from 'react';

import { pdfjs, Document, Page } from 'react-pdf';

import Prismic from 'prismic-javascript';

import { prismicApiEndpoint, prismicAccessToken } from '../../helpers/constants';

import Footer from '../../components/Footer';

import theme from '../../styles/theme';

const PrismicClient = Prismic.client(prismicApiEndpoint, { prismicAccessToken });

const Doc = () => {
  const [doc, setDocData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const { grey2 } = theme.palette.colors;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    const fetchData = async () => {
      const response = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'innovation')
      );

      if (response) {
        setDocData(response.results[0]);
      }
    };
    fetchData();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (!doc) return <React.Fragment />;

  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: grey2
        }}
      >
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: grey2,
            padding: '150px 0'
          }}
        >
          <Document
            file={doc.data.pdf.url}
            options={{ workerSrc: 'pdf.worker.js' }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1} `} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Doc;
